import { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { declensionNoun } from 'helpers/declensionNoun';
import { getTextFromMinMaxValue } from 'helpers/getTextFromMinMaxValue';
import { useOnScreen } from 'shared/hooks/useOnScreen';
import { TTour } from 'shared/types/tours.types';
import { TRANSFORM_TEXT, URL_FOR_IMG } from 'shared/constants/const';
import noImage from 'assets/images/noimage.jpg';
import { ReactComponent as ArrowButton } from 'assets/images/arrow-button.svg';
import { ShareBlog } from 'entities/blog/ShareBlog/ShareBlog';

type TTourItemTopSectionProps = {
  tourItem: TTour;
  handlePortal: () => void;
};

type TParamsTourProps = {
  seasonText?: string;
  groupText: string;
  ageText: string;
  durationText: string;
  priceText: string;
  handlePortal: () => void;
};

const ParamsTour = ({ seasonText, groupText, ageText, durationText, priceText }: TParamsTourProps) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen((prevState) => !prevState);
  };

  // Обработчик для скрытия модального окна при прокрутке
  const hideModalOnScroll = () => {
    if (isModalOpen) {
      setModalOpen(false); // Скрываем модалку при любой прокрутке
    }
  };

  // Подписка на событие scroll при монтировании компонента
  useEffect(() => {
    window.addEventListener('scroll', hideModalOnScroll);

    // Отмена подписки при размонтировании компонента
    return () => {
      window.removeEventListener('scroll', hideModalOnScroll);
    };
  }, []);

  return (
    <>
      <div className='tour-item__params-content'>
        {!!seasonText && (
          <div className='tour-item__params-group'>
            <div className='tour-item__params-name'>Сезон</div>
            <div className='tour-item__params-value'>{seasonText}</div>
          </div>
        )}
        <div className='tour-item__params-group'>
          <div className='tour-item__params-name'>Группа</div>
          <div className='tour-item__params-value'>{groupText}</div>
        </div>
        <div className='tour-item__params-group'>
          <div className='tour-item__params-name'>Возраст</div>
          <div className='tour-item__params-value'>{ageText}</div>
        </div>
        <div className='tour-item__params-group'>
          <div className='tour-item__params-name'>Длительность</div>
          <div className='tour-item__params-value'>{durationText}</div>
        </div>
        <div className='tour-item__params-group'>
          <div className='tour-item__params-name'>Стоимость *1</div>
          <div className='tour-item__params-value'>
            от {priceText}
            <button onClick={toggleModal} className='info-icon'>
              <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M4.43634 16.8379C3.24867 14.97 2.79297 12.7291 3.15677 10.5457C3.52056 8.36229 4.6782 6.39026 6.40734 5.00835C8.13649 3.62644 10.3152 2.93208 12.5251 3.05864C14.735 3.1852 16.8202 4.12374 18.3803 5.69403C19.9404 7.26431 20.8653 9.35561 20.9775 11.5663C21.0896 13.7769 20.3811 15.9511 18.9879 17.6712C17.5948 19.3913 15.6152 20.5361 13.4295 20.8856C11.2438 21.2352 9.00595 20.7649 7.14582 19.5651L4.58082 19.9936C4.50229 20.0068 4.42176 20.0009 4.34593 19.9767C4.27011 19.9524 4.20118 19.9103 4.14488 19.854C4.08857 19.7977 4.04653 19.7288 4.02225 19.653C3.99797 19.5771 3.99214 19.4966 4.00526 19.4181L4.43634 16.8379Z'
                  fill='#DB2947'
                />
                <path d='M12.002 12V15V12Z' fill='white' />
                <path
                  d='M12.002 12V15'
                  stroke='white'
                  stroke-width='2'
                  stroke-linecap='square'
                  stroke-linejoin='round'
                />
                <rect x='11' y='8' width='2' height='2' fill='white' />
              </svg>
            </button>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <div className='modal-overlay' onClick={toggleModal}>
          <div className='modal-content' onClick={(e) => e.stopPropagation()}>
            <p>Обратите внимание!</p>
            <p>
              Не является публичной офертой. Точные условия по предложению предоставит Туроператор после оформления
              заявки.
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export const TourItemTopSection = ({ tourItem, handlePortal }: TTourItemTopSectionProps) => {
  const { title, productType, preview, operator, minAge, maxAge, minGroupSize, maxGroupSize } = tourItem;
  const history = useHistory();

  const orderMobileRef = useRef<HTMLDivElement>(null);
  const isOrderMobilOnScreen = useOnScreen(orderMobileRef);

  const orderDesktopRef = useRef<HTMLDivElement>(null);
  const isOrderDesktopOnScreen = useOnScreen(orderDesktopRef);

  const priceText = `${tourItem?.price.toLocaleString('ru-RU')} ₽`;
  const ageText = getTextFromMinMaxValue(minAge, maxAge, { key: 'age' });
  const groupText = getTextFromMinMaxValue(minGroupSize, maxGroupSize, { label: 'чел' });
  const durationText = tourItem?.duration ? `${tourItem?.duration} ${declensionNoun(tourItem?.duration, 'day')}` : '-';

  const backHandler = () => {
    history.push('/tours');
  };

  const bg = preview.path ? `${URL_FOR_IMG}/${preview.path.replaceAll(' ', '%20')}` : noImage;

  const nameOperator = operator?.shortName || '';
  const phoneOperator = operator?.phone || operator?.contactPhone || '';
  const emailOperator = operator?.email || operator?.contactEmail || '';
  const partnerLink = operator?.partnerLink
    ? /^https?:\/\//i.test(operator.partnerLink)
      ? operator.partnerLink
      : `http://${operator.partnerLink}`
    : '';

  const monthNames = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ];

  const seasonText =
    !tourItem.startMonth || !tourItem.endMonth || (tourItem.startMonth == 1 && tourItem.endMonth == 12)
      ? 'Круглый год'
      : monthNames[tourItem.startMonth - 1] + '-' + monthNames[tourItem.endMonth - 1];

  return (
    <div className='tour-item__header-wrapper'>
      <section className='tour-item__header' ref={orderMobileRef}>
        <div className='tour-item__image' style={{ backgroundImage: `url(${bg})` }}></div>
        <button type='button' className='tour-item__back' onClick={backHandler}>
          <ArrowButton className='tour-item__back__icon' />
          Назад
        </button>
        {productType === TRANSFORM_TEXT.productType.EXCURSION && (
          <div className='tour-item__type'>{TRANSFORM_TEXT.productType.EXCURSION}</div>
        )}
        <h1 className='tour-item__title'>
          <span>{title}</span>
        </h1>
        <button
          type='button'
          className={classNames('tour-item__order tour-item__order_mobile', { fixed: !isOrderMobilOnScreen })}
          onClick={handlePortal}
        >
          заказать
        </button>
      </section>
      <section className='tour-item__operator'>
        <div className='container tour-item__operator__container'>
          <div className='tour-item__operator-content'>
            {!!nameOperator && (
              <div
                className='tour-item__operator-name'
                onClick={() => {
                  if (!!partnerLink) {
                    window.open(partnerLink, '_blank', 'noopener,noreferrer');
                  }
                }}
                style={{ cursor: !!partnerLink ? 'pointer' : 'default' }}
              >
                {nameOperator}
              </div>
            )}

            {!!phoneOperator && <div className='tour-item__operator-phone'>{phoneOperator}</div>}
            {!!emailOperator && <div className='tour-item__operator-email'>{emailOperator}</div>}
          </div>
        </div>
      </section>
      <section className='tour-item__params'>
        <div className='container tour-item__params__container' ref={orderDesktopRef}>
          <ParamsTour
            seasonText={seasonText}
            groupText={groupText}
            ageText={ageText}
            durationText={durationText}
            priceText={priceText}
            handlePortal={handlePortal}
          />
        </div>
        <ShareBlog />
      </section>

      <div className={classNames('tour-item__params tour-item__params_fixed', { show: !isOrderDesktopOnScreen })}>
        {!isOrderDesktopOnScreen && (
          <div className='container tour-item__container'>
            <div className='tour-item__name'>{tourItem.title}</div>
            <ParamsTour
              groupText={groupText}
              ageText={ageText}
              durationText={durationText}
              priceText={priceText}
              handlePortal={handlePortal}
            />
          </div>
        )}
      </div>
    </div>
  );
};
